.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Coming Soon Page */
.container {
  background-image: url('./Assets/images/ctComingSoon.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-coming-soon {
  background-image: url('./Assets/images/ctComingSoon.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.continer-about-us-landing {
  background-image: url('./Assets/images/aboutUsLanding.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 48vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-products {
  background-image: url('./Assets/images/allProducts.png');
  background-size: cover; /* Adjusts height to fit the entire image while maintaining aspect ratio */
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh; /* Set a minimum height equal to viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-get-quote {
  background-image: url('./Assets/images/getQuote.jpg');
  background-size: 100% auto, contain; /* Adjusts height to fit the entire image while maintaining aspect ratio */
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-about-us {
  background-image: url('./Assets/images/aboutUsBanner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-contact-us {
  background-image: url('./Assets/images/contactUsBanner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-product {
  background-image: url('./Assets/images/productBenner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-industry {
  background-image: url('./Assets/images/industriesBanner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-get-quote {
  background-image: url('./Assets/images/getQuoteBanner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-footer {
  background-color: #003D83;
  background-size: cover;
  background-position: center;
}

.Chemicals {
  background-color: #EFEFEF;
}

.line-footer{
  background-color: #0157B9;  
}

.ChemicalHeading{
  margin-top: 15px;
  font-size: medium;
  font-weight: 400;
}

.center-text {
  text-align: center;
}

.heading {
  color: white;
  font-weight: 100;
  font-size: 60px;
}

.header {
  min-height: 80px;
}

.highlight {
  color: #FBC311;
  font-weight: 700;
}

.highlight-md {
  color: #FBC311;
  font-weight: 500;
  font-size: 72px;
}

.highlight-ab {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 72px;
}

.description {
  color: white;
  font-weight: 60;
  font-size: 25px;
  color: #FFFFFF;
}

.description-md {
  color: white;
  font-weight: 55;
  font-size: 56px;
  color: #FFFFFF;
}

.location-card:hover{
  cursor: pointer;
}

.ant-modal-title {
  text-align: center;
}

.AboutUsPic {
  min-height: 50px;
  background-color: '#D9D9D9';
}

.centeredCard {
  display: flex;
  justify-content: center;
}

.custom-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  width: 70%;
}

/* Media Queries */
@media screen and (max-width: 600px) {
  .heading, .big-texts {
    text-align: center;
    font-size: 45px;
  }
}

@media screen and (max-width: 600px) {
  .heading, .center-text {
    text-align: center;
    font-size: 40px;
  }
}

@media screen and (max-width: 600px) {
  .adres {
    /* If the screen width is less than 600px, apply these styles */
    overflow-wrap: break-word; /* For older browsers */
    word-wrap: break-word; /* For modern browsers */
  }
}

@media screen and (max-width: 600px) {
  .AboutUsPic {
    margin-top: 25px;
    margin-left: 45px;
  }
}

@media screen and (max-width: 600px) {
  .header-icon {
    height: 28px;
    margin-left: 18px;
  }
}

@media screen and (max-width: 600px) {
  .chotu-letters {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .chotu-letters-footers {
    text-align: center;
    margin-right: 17px;
  }
}

@media screen and (max-width: 600px) {
  .sm-about-us {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .little-contact {
    max-width: 85%;
    border-radius: 20px;
  }
}

@media screen and (min-width: 600px) {
  .little-contact {
    max-width: 85%;
    border-radius: 20px;
  }
}

@media screen and (max-width: 600px) {
  .footer-small {
    border-radius: 7px;
  }
}

@media screen and (max-width: 600px) {
  .continer-about-us-landing {
    height: 100vh;
  }
}

@media screen and (max-width: 600px) {
  .glbl-prsc {
    font-size: 38px;
  }
}

@media screen and (min-width: 600px) {
  .glbl-prsc {
    font-size: 58px;
  }
}

@media screen and (max-width: 600px) {
  .cards {
    width: 100%;
    border-radius: 0%;
  }
}

@media screen and (min-width: 600px) {
  .cards {
    width: 55%;
    border-radius: 15px;
  }
}

@media screen and (min-width: 600px) {
  .product-title {
    width: 60%;
  }
}

@media screen and (min-width: 600px) {
  .footer-text {
    position: absolute;
    right: 5px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: 100vh;
  }
}

@media screen and (max-width: 600px) {
  .industrialize {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .about-small-para{
    margin-left: 21px;
    margin-right: 17px;
  }
}